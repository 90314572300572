var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    {
      attrs: {
        wrap: "",
        "justify-start": "",
        "pa-2": "",
        "pa-sm-4": "",
        "pa-md-8": "",
      },
    },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "", "justify-start": "", "pb-4": "" } },
            [
              _c("v-spacer"),
              _c(
                "v-flex",
                { attrs: { xs12: "", sm12: "", md12: "", lg8: "", xl6: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "", "justify-end": "" } },
                    [
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            xs6: "",
                            sm6: "",
                            md3: "",
                            "align-self-center": "",
                            "pa-2": "",
                            "px-2": "",
                          },
                        },
                        [
                          _c("AppDatePicker", {
                            attrs: {
                              min: _vm.minDate,
                              max: _vm.maxDate,
                              range: "",
                              placeholder: _vm.$t(
                                "pages.schedules.select_month"
                              ),
                              hideDetails: "",
                            },
                            on: {
                              submit: function ($event) {
                                return _vm.prepareFetchData(false)
                              },
                            },
                            model: {
                              value: _vm.filter.date,
                              callback: function ($$v) {
                                _vm.$set(_vm.filter, "date", $$v)
                              },
                              expression: "filter.date",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            xs6: "",
                            sm6: "",
                            md3: "",
                            "text-right": "",
                            "align-self-center": "",
                            "pa-2": "",
                            "px-2": "",
                          },
                        },
                        [
                          _c("v-select", {
                            key: _vm.filter.type,
                            attrs: {
                              placeholder: _vm.$t("filters.type"),
                              items: _vm.SHIFT_STATUS,
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              "item-text": "label",
                              "item-value": "key",
                              clearable: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.prepareFetchData(false)
                              },
                            },
                            model: {
                              value: _vm.filter.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.filter, "type", $$v)
                              },
                              expression: "filter.type",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            xs6: "",
                            sm6: "",
                            md3: "",
                            "align-self-center": "",
                            "text-right": "",
                            "pa-2": "",
                          },
                        },
                        [
                          _c("AppNavButton", {
                            attrs: {
                              route: { name: "services.add" },
                              "custom-class": "pa-5",
                              block: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "content",
                                fn: function () {
                                  return [
                                    _c("v-icon", { attrs: { size: "14px" } }, [
                                      _vm._v("mdi-plus"),
                                    ]),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("pages.services.add_service")
                                        ) +
                                        " "
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-layout",
            { attrs: { wrap: "", "justify-start": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("AppSimpleTable", {
                    attrs: {
                      headings: _vm.headings,
                      items: _vm.list,
                      pagination: _vm.pagination,
                      "empty-title": "pages.services.no_service_to_show",
                    },
                    on: {
                      paginate: (val) => (
                        (_vm.pagination.page = val), this.prepareFetchData(true)
                      ),
                      delete: _vm.toggleDelete,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("DeleteConfirmation", {
        attrs: { item: _vm.selectedItem },
        on: { cancel: _vm.toggleDelete, submit: _vm.deleteService },
        model: {
          value: _vm.showDelete,
          callback: function ($$v) {
            _vm.showDelete = $$v
          },
          expression: "showDelete",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }